
import GoogleMyBusinessLoginCard from '@/components/google/GoogleMyBusinessLoginCard.vue';
import GoogleMyBusinessSettingsCard from '@/components/google/GoogleMyBusinessSettingsCard.vue';
import { ProjectViewMixin } from '@/mixins/ProjectViewMixin';
import { Bot, GoogleBusinessPlatformData, SupportedPlatform } from 'ignite360-core';
import _merge from 'lodash.merge';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'project-social-platform-google-my-business',
  components: { GoogleMyBusinessLoginCard, GoogleMyBusinessSettingsCard },
})
export default class ProjectSocialPlatformGoogleMyBusiness extends mixins(ProjectViewMixin) {
  onConnect(data: Partial<GoogleBusinessPlatformData>) {
    const newBot: Bot = _merge({}, this.bot, {
      platformData: {
        [SupportedPlatform.GoogleBusiness]: data,
      },
    });
    this.$store.commit('bots/updateBot', newBot);
  }

  onDisconnect() {
    const newBot: Bot = _merge({}, this.bot, {
      platformData: {
        [SupportedPlatform.GoogleBusiness]: {
          accountId: '',
          locationId: '',
        },
      },
    });
    this.$store.commit('bots/updateBot', newBot);
  }
}
