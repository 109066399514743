
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import isEqual from 'fast-deep-equal/es6';
import { GoogleBusinessPlatformData, SupportedPlatform } from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';

type GoogleMyBusinessSettings = Pick<
  GoogleBusinessPlatformData,
  'enablePosting' | 'accountId' | 'locationId'
>;

@Component({
  name: 'google-my-business-settings-card',
})
export default class GoogleMyBusinessSettingsCard extends mixins(BotMixin) {
  googleMyBusinessSettings = this.resetValue();

  isSubmitting = false;

  get settingsHaveChanged(): boolean {
    return !isEqual(this.googleMyBusinessSettings, this.resetValue());
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      await this.$updateBotPlatform({
        id: this.bot.id,
        platform: SupportedPlatform.GoogleBusiness,
        data: this.googleMyBusinessSettings,
      });
      this.$notify.success('Successfully updated Google-My-Business-settings');
    } catch (e) {
      this.$notify.error({
        title: 'Updating Google-My-Business-settings failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  @Watch('bot', { immediate: true, deep: true })
  private reset() {
    this.googleMyBusinessSettings = this.resetValue();
  }

  private resetValue(): GoogleMyBusinessSettings {
    return {
      enablePosting: this.bot.platformData.googleBusiness.enablePosting,
      accountId: this.bot.platformData.googleBusiness.accountId,
      locationId: this.bot.platformData.googleBusiness.locationId,
    };
  }
}
